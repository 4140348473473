
* {
    margin: 0;
    padding: 0;
}

.artitificia-head-step {
    padding: 0 30px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #ebebeb;
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
        font-size: 12px;
        .step-box {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 200;
            .step-tag {
                box-sizing: border-box;
                width: 70px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                color: #fff !important;
                border-radius: 2px;
                background-color: #caccda;
                .icon {
                    margin-right: 5px;
                }
            }
            .arrow-box {
                display: flex;
                align-items: center;
                margin: 0 28px;
                color: #caccda;
                font-size: 15px;
                .dot-list {
                    display: flex;
                    overflow: hidden;
                    padding: 0;
                    .dot-item {
                        box-sizing: border-box;
                        width: 4px;
                        height: 4px;
                        margin-right: 7px;
                        border-radius: 50%;
                        background-color: #caccda;
                    }
                }
                .icon {
                    font-weight: 700;
                    background-color: #fff !important;
                }
            }
        }
        .left-box {
            text-align: center;
            cursor: pointer;
            color: #ff2000;
            min-width: 40px;
            .iconfont,
            .icon {
                transform: rotate(-90deg);
                font-size: 27px;
            }
            .back {
                color: #1122d8;
                .icon {
                    transform: rotate(0);
                }
            }
        }
        .right-next {
            min-width: 40px;
            text-align: center;
            cursor: pointer;
            color: #1122d8;
            .icon {
                font-weight: 700;
                font-size: 27px;
            }
        }
    }
}
