
.active {
    color: #fff;
    background-color: #1122d8;
    border-color: #1122d8 !important;
}
::v-deep .el-input--mini .el-input__inner {
    height: 32px;
}
.artificial-paper-filter {
    // padding: 18px 0;
    margin-bottom: 20px;
    padding: 0 28px;
    .main {
        color: #333;
        font-size: 16px;
        .question-type {
            display: flex;
            align-items: center;
            .question-type-list {
                display: flex;
                font-size: 14px;
                color: #666;
                .question-type-item {
                    box-sizing: border-box;
                    width: 62px;
                    height: 32px;
                    margin-left: 10px;
                    text-align: center;
                    line-height: 32px;
                    border: 1px solid #d2d2d2;
                    cursor: pointer;
                    transition: all 0.3s;
                }
            }
        }
        .question-library-cate {
            display: flex;
            align-items: center;
            margin-left: 40px;
            .text {
                margin-right: 17px;
            }
        }
        .second-step {
            flex: 1;
            margin-left: 20px;
            .search {
                width: 350px;
            }
        }
    }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #666;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #1122d8;
    border-color: #1122d8;
}
::v-deep .el-input__inner {
    height: 30px;
}
