
* {
    padding: 0;
    margin: 0;
}
.active-arrow {
    // transition: transform 0.2s;
    transform-origin: center;
    transform: rotate(90deg);
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
.artificial-paper-set-score {
    box-sizing: border-box;
    margin: 0 15px 0 30px;
    border: 1px solid #d2d2d2;
    height: 100%;
    color: #333333;
    cursor: default;
    .paper-info-title {
        padding: 10px 0;
        text-align: center;
        font-size: 17px;
        border-bottom: 1px solid #eeeeee;
    }
    .head {
        padding: 12px 12px 16px 0;
        font-size: 15px;
        .flex {
            margin-bottom: 10px;
        }
        .text {
            flex: 3;
            margin-right: 8px;
            white-space: nowrap;
            text-align: end;
        }
        .input {
            flex: 7;
        }
        ::v-deep .el-input__inner {
            height: 30px;
        }
        .paper-total-score {
            margin-bottom: 0;
            ::v-deep .el-input__inner {
                width: 100px;
            }
            ::v-deep .el-input__suffix {
                position: relative;
            }
            ::v-deep .el-input {
                display: flex;
                align-items: center;
            }
            .modify {
                height: 100%;
                margin-bottom: 0;
                margin-left: 10px;
                color: #333;
            }
        }
    }
    .set-score-box {
        padding: 0 17px 0 10px;
        .question-type-item {
            margin-bottom: 7px;
            font-size: 13px;
            .question-content .question-item {
                margin-top: 7px;
            }
            .input {
                width: 60px;
                margin-right: 8px;
            }
            .left-title {
                flex: 1;
                cursor: pointer;
                .icon {
                    display: flex;
                    justify-content: center;
                    width: 18px;
                }
            }
            ::v-deep .el-input__inner {
                height: 24px;
                text-align: center;
            }
        }
    }
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
