
.paper-preview {
    padding: 0 25px;
    color: #333;
    .head {
        font-size: 18px;
        text-align: center;
        margin-bottom: 23px;
        .total-score {
            font-size: 14px;
            color: #343441;
        }
    }
    .main {
        font-size: 13px;
        .type-item {
            .type-box {
                display: flex;
                align-items: center;
                margin-bottom: 11px;
                font-size: 15px;
                .info {
                    margin-left: 7px;
                    font-size: 13px;
                }
            }
        }
    }
}
